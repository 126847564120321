<template>
    <div class="h2-content m-f">
      <el-icon @click="showTitle = !showTitle" class="icon"><CaretBottom v-if="showTitle"/><CaretLeft v-else/></el-icon>
      <h5>{{name}}</h5>
    </div>
    <div v-if="showTitle">
      <slot />
    </div>
</template>
<script>
import { defineComponent, ref,reactive,toRefs,watch,onMounted} from "vue";
export default defineComponent({
  name: 'MyDropOff',
  props: {
    name: {
      type: String,
      default() {
        return ''
      }
    },
  },
  setup(props,context) {
    const $_this = reactive({
      showTitle:true
    })

    onMounted(() => {//组件挂载完成后执行的函数
    })

    return {
      ...toRefs($_this),
    }
  }
});
</script>
<style lang="less" scoped>
.m-f{
  margin-left: -1em;;
}
</style>