/**
 * vue3的动态路由写法
 */
 import {createRouter, createWebHashHistory} from 'vue-router'
//  import store from '../store'
//  import { getToken } from '@/utils/auth'
//  import defaultSettings from '@/settings'
//  import { login, logout } from '@/utils/is4'
var routes = [
	// {
	//   path: '/',
	//   // name: 'home',
	// 	// component: () => import(`@/views/account/home`),
	//   component: () => import(`@/views/account/home`),
	//   hidden: true,
	//   meta: {
	// 	title: '首页'
	//   }
	// },
	{
	  path: '/',
	  // component:(resolve) => require([`@/layout`], resolve),
	  component: () => import(`@/layout`),
	  	children: [
			// {
			// 	path: '/platform/version',
			// 	name: 'platformVersion',
			// 	component: () => import('../views/platform/version.vue'),
			// 	meta: {
			// 		title: "版本清单",
			// 		path: "/platform/version"
			// 	}
			// },
			{
				path: '/platform/overview',
				name: 'platformOverview',
				component: () => import('../views/platform/overview.vue'),
				meta: {
					title: "概述",
					path: "/platform/overview"
				}
			},
			{
				path: '/platform/update',
				name: 'platformUpdate',
				component: () => import('../views/platform/update.vue'),
				meta: {
					title: "更新说明",
					path: "/platform/update"
				}
			},
			{
				path: '/error-code-table',
				name: 'errorCodeTable',
				component: () => import('../views/error-code-table.vue'),
				meta: {
					title: "错误码表",
					path: "/error-code-table"
				}
			},
			{
				path: '/guide/sdk/record',
				name: 'guideSdkRecord',
				component: () => import('../views/guide/sdk-record.vue'),
				meta: {
					title: "发布记录",
					path: "/guide/sdk/record"
				}
			},
			{
				path: '/guide/sdk/introduction',
				name: 'guideSdkIntroduction',
				component: () => import('../views/guide/sdk-introduction.vue'),
				meta: {
					title: "柔灵SDK简介",
					path: "/guide/sdk/introduction"
				}
			},
			// UniApp Android SDK
			{
				path: '/guide/UniApp/access',
				name: 'guideUniAppAccess',
				component: () => import('../views/guide/UniApp/access.vue'),
				meta: {
					title: "快速接入SDK",
					path: "/guide/UniApp/access"
				}
			},
			{
				path: '/guide/UniApp/scan',
				name: 'guideUniAppScan',
				component: () => import('../views/guide/UniApp/scan.vue'),
				meta: {
					title: "扫描设备",
					path: "/guide/UniApp/scan"
				}
			},
			{
				path: '/guide/UniApp/connection',
				name: 'guideUniAppConnection',
				component: () => import('../views/guide/UniApp/connection.vue'),
				meta: {
					title: "连接设备",
					path: "/guide/UniApp/connection"
				}
			},
			{
				path: '/guide/UniApp/addEvent',
				name: 'guideUniAppAddEvent',
				component: () => import('../views/guide/UniApp/addEvent.vue'),
				meta: {
					title: "数据采集过程中添加事件",
					path: "/guide/UniApp/addEvent"
				}
			},
			{
				path: '/guide/UniApp/intercept',
				name: 'guideUniAppIntercept',
				component: () => import('../views/guide/UniApp/intercept.vue'),
				meta: {
					title: "原始数据截取",
					path: "/guide/UniApp/intercept"
				}
			},
			{
				path: '/guide/UniApp/sleep',
				name: 'guideUniAppSleep',
				component: () => import('../views/guide/UniApp/sleep.vue'),
				meta: {
					title: "监听睡眠分期",
					path: "/guide/UniApp/sleep"
				}
			},
			{
				path: '/guide/UniApp/filtering',
				name: 'guideUniAppFiltering',
				component: () => import('../views/guide/UniApp/filtering.vue'),
				meta: {
					title: "设置滤波参数",
					path: "/guide/UniApp/filtering"
				}
			},
			{
				path: '/guide/UniApp/endRecord',
				name: 'guideUniAppEndRecord',
				component: () => import('../views/guide/UniApp/endRecord.vue'),
				meta: {
					title: "结束记录",
					path: "/guide/UniApp/endRecord"
				}
			},
			{
				path: '/guide/UniApp/startRecord',
				name: 'guideUniAppStartRecord',
				component: () => import('../views/guide/UniApp/startRecord.vue'),
				meta: {
					title: "开始记录",
					path: "/guide/UniApp/startRecord"
				}
			},
			{
				path: '/guide/UniApp/bodyPosition',
				name: 'guideUniAppBodyPosition',
				component: () => import('../views/guide/UniApp/bodyPosition.vue'),
				meta: {
					title: "获取体位",
					path: "/guide/UniApp/bodyPosition"
				}
			},
			{
				path: '/guide/UniApp/wearingState',
				name: 'guideUniAppWearingState',
				component: () => import('../views/guide/UniApp/wearingState.vue'),
				meta: {
					title: "获取设备佩戴状态",
					path: "/guide/UniApp/wearingState"
				}
			},
			{
				path: '/guide/UniApp/connectionStatus',
				name: 'guideUniAppConnectionStatus',
				component: () => import('../views/guide/UniApp/connectionStatus.vue'),
				meta: {
					title: "获取连接状态",
					path: "/guide/UniApp/connectionStatus"
				}
			},
			{
				path: '/guide/UniApp/equipmentSignal',
				name: 'guideUniAppEquipmentSignal',
				component: () => import('../views/guide/UniApp/equipmentSignal.vue'),
				meta: {
					title: "获取信号强度",
					path: "/guide/UniApp/equipmentSignal"
				}
			},
			{
				path: '/guide/UniApp/electricQuantity',
				name: 'guideUniAppElectricQuantity',
				component: () => import('../views/guide/UniApp/electricQuantity.vue'),
				meta: {
					title: "获取设备电量",
					path: "/guide/UniApp/electricQuantity"
				}
			},
			{
				path: '/guide/UniApp/deviceInfo',
				name: 'guideUniAppDeviceInfo',
				component: () => import('../views/guide/UniApp/deviceInfo.vue'),
				meta: {
					title: "获取设备信息",
					path: "/guide/UniApp/deviceInfo"
				}
			},
			{
				path: '/guide/UniApp/dataMonitor',
				name: 'guideUniAppDataMonitor',
				component: () => import('../views/guide/UniApp/dataMonitor.vue'),
				meta: {
					title: "实时数据监听",
					path: "/guide/UniApp/dataMonitor"
				}
			},
			{
				path: '/guide/UniApp/quality',
				name: 'guideUniAppQuality',
				component: () => import('../views/guide/UniApp/quality.vue'),
				meta: {
					title: "数据质量检测",
					path: "/guide/UniApp/quality"
				}
			},
			{
				path: '/guide/UniApp/releaseSDK',
				name: 'guideUniAppReleaseSDK',
				component: () => import('../views/guide/UniApp/releaseSDK.vue'),
				meta: {
					title: "释放SDK",
					path: "/guide/UniApp/releaseSDK"
				}
			},
			{
				path: '/guide/UniApp/shutdown',
				name: 'guideUniAppShutdown',
				component: () => import('../views/guide/UniApp/shutdown.vue'),
				meta: {
					title: "断开设备",
					path: "/guide/UniApp/shutdown"
				}
			},
			{
				path: '/guide/UniApp/stopScan',
				name: 'guideUniAppstopScan',
				component: () => import('../views/guide/UniApp/stopScan.vue'),
				meta: {
					title: "停止扫描",
					path: "/guide/UniApp/stopScan"
				}
			},
			// 安卓SDK
			{
				path: '/guide/android/access',
				name: 'guideAndroidAccess',
				component: () => import('../views/guide/android/access.vue'),
				meta: {
					title: "快速接入SDK",
					path: "/guide/android/access"
				}
			},
			{
				path: '/guide/android/initialization',
				name: 'guideAndroidInitialization',
				component: () => import('../views/guide/android/initialization.vue'),
				meta: {
					title: "初始化SDK",
					path: "/guide/android/initialization"
				}
			},
			{
				path: '/guide/android/system-permission',
				name: 'androidSystemPermission',
				component: () => import('../views/guide/android/system-permission.vue'),
				meta: {
					title: "获取系统权限获取情况",
					path: "/guide/android/system-permission"
				}
			},
			{
				path: '/guide/android/scan',
				name: 'guideAndroidScan',
				component: () => import('../views/guide/android/scan.vue'),
				meta: {
					title: "扫描设备",
					path: "/guide/android/scan"
				}
			},
			{
				path: '/guide/android/stopScan',
				name: 'guideAndroidstopScan',
				component: () => import('../views/guide/android/stopScan.vue'),
				meta: {
					title: "停止扫描",
					path: "/guide/android/stopScan"
				}
			},
			{
				path: '/guide/android/connection',
				name: 'guideAndroidConnection',
				component: () => import('../views/guide/android/connection.vue'),
				meta: {
					title: "连接设备",
					path: "/guide/android/connection"
				}
			},
			{
				path: '/guide/android/dataReporting',
				name: 'guideAndroidDataReporting',
				component: () => import('../views/guide/android/dataReporting.vue'),
				meta: {
					title: "开启数据实时上报",
					path: "/guide/android/dataReporting"
				}
			},
			{
				path: '/guide/android/shutdown',
				name: 'guideAndroidShutdown',
				component: () => import('../views/guide/android/shutdown.vue'),
				meta: {
					title: "关闭设备",
					path: "/guide/android/shutdown"
				}
			},
			{
				path: '/guide/android/connectionStatus',
				name: 'guideAndroidConnectionStatus',
				component: () => import('../views/guide/android/connectionStatus.vue'),
				meta: {
					title: "获取连接状态",
					path: "/guide/android/connectionStatus"
				}
			},
			{
				path: '/guide/android/startRecord',
				name: 'guideAndroidStartRecord',
				component: () => import('../views/guide/android/startRecord.vue'),
				meta: {
					title: "开始记录",
					path: "/guide/android/startRecord"
				}
			},
			{
				path: '/guide/android/endRecord',
				name: 'guideAndroidEndRecord',
				component: () => import('../views/guide/android/endRecord.vue'),
				meta: {
					title: "结束记录",
					path: "/guide/android/endRecord"
				}
			},
			{
				path: '/guide/android/dataMonitor',
				name: 'guideAndroidDataMonitor',
				component: () => import('../views/guide/android/dataMonitor.vue'),
				meta: {
					title: "实时数据监听",
					path: "/guide/android/dataMonitor"
				}
			},
			{
				path: '/guide/android/sleep',
				name: 'guideAndroidSleep',
				component: () => import('../views/guide/android/sleep.vue'),
				meta: {
					title: "监听睡眠分期",
					path: "/guide/android/sleep"
				}
			},
			{
				path: '/guide/android/quality',
				name: 'guideAndroidQuality',
				component: () => import('../views/guide/android/quality.vue'),
				meta: {
					title: "获取采集的数据质量",
					path: "/guide/android/quality"
				}
			},
			{
				path: '/guide/android/bodyPosition',
				name: 'guideAndroidBodyPosition',
				component: () => import('../views/guide/android/bodyPosition.vue'),
				meta: {
					title: "获取体位",
					path: "/guide/android/bodyPosition"
				}
			},
			{
				path: '/guide/android/electricQuantity',
				name: 'guideAndroidElectricQuantity',
				component: () => import('../views/guide/android/electricQuantity.vue'),
				meta: {
					title: "获取设备电量",
					path: "/guide/android/electricQuantity"
				}
			},
			{
				path: '/guide/android/equipmentSignal',
				name: 'guideAndroidEquipmentSignal',
				component: () => import('../views/guide/android/equipmentSignal.vue'),
				meta: {
					title: "获取设备信号强度",
					path: "/guide/android/equipmentSignal"
				}
			},
			{
				path: '/guide/android/frontalPatch',
				name: 'guideAndroidFrontalPatch',
				component: () => import('../views/guide/android/frontalPatch.vue'),
				meta: {
					title: "是否佩戴额贴",
					path: "/guide/android/frontalPatch"
				}
			},
			{
				path: '/guide/android/intercept',
				name: 'guideAndroidIntercept',
				component: () => import('../views/guide/android/intercept.vue'),
				meta: {
					title: "原始数据截取",
					path: "/guide/android/intercept"
				}
			},
			{
				path: '/guide/android/filtering',
				name: 'guideAndroidFiltering',
				component: () => import('../views/guide/android/filtering.vue'),
				meta: {
					title: "设置滤波参数",
					path: "/guide/android/filtering"
				}
			},
			{
				path: '/guide/android/monitorIndicator',
				name: 'guideAndroidMonitorIndicator',
				component: () => import('../views/guide/android/monitorIndicator.vue'),
				meta: {
					title: "监听实时指标",
					path: "/guide/android/monitorIndicator"
				}
			},
			{
				path: '/guide/android/addEvent',
				name: 'guideAndroidAddEvent',
				component: () => import('../views/guide/android/addEvent.vue'),
				meta: {
					title: "数据采集过程中添加事件",
					path: "/guide/android/addEvent"
				}
			},
			{
				path: '/guide/android/offLine',
				name: 'androidOffLine',
				component: () => import('../views/guide/android/offLine.vue'),
				meta: {
					title: "查询离线数据",
					path: "/guide/android/offLine"
				}
			},
			{
				path: '/guide/android/merge-offline',
				name: 'androidMergeOffLine',
				component: () => import('../views/guide/android/merge-offline.vue'),
				meta: {
					title: "合并离线数据",
					path: "/guide/android/merge-offline"
				}
			},
			{
				path: '/guide/android/erasing-equipment',
				name: 'androidErasingEquipment',
				component: () => import('../views/guide/android/erasing-equipment.vue'),
				meta: {
					title: "擦除设备离线数据",
					path: "/guide/android/erasing-equipment"
				}
			},
			{
				path: '/guide/android/unoffline',
				name: 'androidUnoffline',
				component: () => import('../views/guide/android/unoffline.vue'),
				meta: {
					title: "取消离线数据合并",
					path: "/guide/android/unoffline"
				}
			},
			{
				path: '/guide/android/meditation-test',
				name: 'androidMeditationTest',
				component: () => import('../views/guide/android/meditation-test.vue'),
				meta: {
					title: "冥想信号检测",
					path: "/guide/android/meditation-test"
				}
			},
			{
				path: '/guide/android/exit-detection',
				name: 'androidExitDetection',
				component: () => import('../views/guide/android/exit-detection.vue'),
				meta: {
					title: "退出冥想检测",
					path: "/guide/android/exit-detection"
				}
			},
			{
				path: '/guide/android/start-meditating',
				name: 'androidStartMeditating',
				component: () => import('../views/guide/android/start-meditating.vue'),
				meta: {
					title: "开始冥想",
					path: "/guide/android/start-meditating"
				}
			},
			{
				path: '/guide/android/meditation-record',
				name: 'androidMeditationRecord',
				component: () => import('../views/guide/android/meditation-record.vue'),
				meta: {
					title: "冥想数据记录",
					path: "/guide/android/meditation-record"
				}
			},
			{
				path: '/guide/android/meditation-score',
				name: 'androidMeditationScore',
				component: () => import('../views/guide/android/meditation-score.vue'),
				meta: {
					title: "获取实时冥想值",
					path: "/guide/android/meditation-score"
				}
			},
			{
				path: '/guide/android/end-meditation',
				name: 'androidEndMeditation',
				component: () => import('../views/guide/android/end-meditation.vue'),
				meta: {
					title: "结束冥想",
					path: "/guide/android/end-meditation"
				}
			},
			{
				path: '/guide/android/explanatoryDocument',
				name: 'guideAndroidExplanatoryDocument',
				component: () => import('../views/guide/android/explanatoryDocument.vue'),
				meta: {
					title: "说明文档",
					path: "/guide/android/explanatoryDocument"
				}
			},
			// Android SDK说明文档
			// {
			// 	path: '/guide/android/sdk/RecordEventCode',
			// 	name: 'RecordEventCode',
			// 	component: () => import('../views/guide/android/sdk/recordEventCode.vue'),
			// 	meta: {
			// 		title: "RecordEventCode",
			// 		path: "/guide/android/sdk/RecordEventCode"
			// 	}
			// },
			// {
			// 	path: '/guide/android/sdk/ConnectResultType',
			// 	name: 'ConnectResultType',
			// 	component: () => import('../views/guide/android/sdk/connectResultType.vue'),
			// 	meta: {
			// 		title: "connectResultType",
			// 		path: "/guide/android/sdk/ConnectResultType"
			// 	}
			// },
			// {
			// 	path: '/guide/android/sdk/BleBean',
			// 	name: 'BleBean',
			// 	component: () => import('../views/guide/android/sdk/bleBean.vue'),
			// 	meta: {
			// 		title: "BleBean",
			// 		path: "/guide/android/sdk/BleBean"
			// 	}
			// },
			// {
			// 	path: '/guide/android/sdk/ScanResultEvent',
			// 	name: 'ScanResultEvent',
			// 	component: () => import('../views/guide/android/sdk/scanResultEvent.vue'),
			// 	meta: {
			// 		title: "ScanResultEvent",
			// 		path: "/guide/android/sdk/ScanResultEvent"
			// 	}
			// },
			// IOS SDK
			{
				path: '/guide/IOS/access',
				name: 'guideIOSAccess',
				component: () => import('../views/guide/IOS/access.vue'),
				meta: {
					title: "快速接入SDK",
					path: "/guide/IOS/access"
				}
			},
			{
				path: '/guide/IOS/initialization',
				name: 'guideIOSInitialization',
				component: () => import('../views/guide/IOS/initialization.vue'),
				meta: {
					title: "初始化SDK",
					path: "/guide/IOS/initialization"
				}
			},
			{
				path: '/guide/IOS/scan',
				name: 'guideIOSScan',
				component: () => import('../views/guide/IOS/scan.vue'),
				meta: {
					title: "扫描设备",
					path: "/guide/IOS/scan"
				}
			},
			{
				path: '/guide/IOS/stopScan',
				name: 'guideIOSstopScan',
				component: () => import('../views/guide/IOS/stopScan.vue'),
				meta: {
					title: "停止扫描",
					path: "/guide/IOS/stopScan"
				}
			},
			{
				path: '/guide/IOS/connection',
				name: 'guideIOSConnection',
				component: () => import('../views/guide/IOS/connection.vue'),
				meta: {
					title: "连接设备",
					path: "/guide/IOS/connection"
				}
			},
			{
				path: '/guide/IOS/connectionStatus',
				name: 'guideIOSConnectionStatus',
				component: () => import('../views/guide/IOS/connectionStatus.vue'),
				meta: {
					title: "获取连接状态",
					path: "/guide/IOS/connectionStatus"
				}
			},
			{
				path: '/guide/IOS/dataMonitor',
				name: 'guideIOSDataMonitor',
				component: () => import('../views/guide/IOS/dataMonitor.vue'),
				meta: {
					title: "实时数据监听",
					path: "/guide/IOS/dataMonitor"
				}
			},
			{
				path: '/guide/IOS/intercept',
				name: 'guideIOSIntercept',
				component: () => import('../views/guide/IOS/intercept.vue'),
				meta: {
					title: "原始数据截取",
					path: "/guide/IOS/intercept"
				}
			},
			{
				path: '/guide/IOS/shutdown',
				name: 'guideIOSShutdown',
				component: () => import('../views/guide/IOS/shutdown.vue'),
				meta: {
					title: "关闭设备连接",
					path: "/guide/IOS/shutdown"
				}
			},
			{
				path: '/guide/IOS/frontalPatch',
				name: 'guideIOSFrontalPatch',
				component: () => import('../views/guide/IOS/frontalPatch.vue'),
				meta: {
					title: "是否佩戴了脑贴",
					path: "/guide/IOS/frontalPatch"
				}
			},
			{
				path: '/guide/IOS/electricQuantity',
				name: 'guideIOSElectricQuantity',
				component: () => import('../views/guide/IOS/electricQuantity.vue'),
				meta: {
					title: "获取设备电量",
					path: "/guide/IOS/electricQuantity"
				}
			},
			{
				path: '/guide/IOS/equipmentSignal',
				name: 'guideIOSEquipmentSignal',
				component: () => import('../views/guide/IOS/equipmentSignal.vue'),
				meta: {
					title: "获取信号强度",
					path: "/guide/IOS/equipmentSignal"
				}
			},
			{
				path: '/guide/IOS/quality',
				name: 'guideIOSQuality',
				component: () => import('../views/guide/IOS/quality.vue'),
				meta: {
					title: "获取采集的数据质量",
					path: "/guide/IOS/quality"
				}
			},
			{
				path: '/guide/IOS/startRecord',
				name: 'guideIOSStartRecord',
				component: () => import('../views/guide/IOS/startRecord.vue'),
				meta: {
					title: "开始记录",
					path: "/guide/IOS/startRecord"
				}
			},
			{
				path: '/guide/IOS/endRecord',
				name: 'guideIOSEndRecord',
				component: () => import('../views/guide/IOS/endRecord.vue'),
				meta: {
					title: "结束记录",
					path: "/guide/IOS/endRecord"
				}
			},
			{
				path: '/guide/IOS/addEvent',
				name: 'guideIOSAddEvent',
				component: () => import('../views/guide/IOS/addEvent.vue'),
				meta: {
					title: "数据采集过程中添加事件",
					path: "/guide/IOS/addEvent"
				}
			},
			{
				path: '/guide/IOS/info',
				name: 'guideIOSInfo',
				component: () => import('../views/guide/IOS/info.vue'),
				meta: {
					title: "获取设备信息",
					path: "/guide/IOS/info"
				}
			},
			{
				path: '/guide/IOS/sleep',
				name: 'guideIOSSleep',
				component: () => import('../views/guide/IOS/sleep.vue'),
				meta: {
					title: "监听睡眠分期",
					path: "/guide/IOS/sleep"
				}
			},
			{
				path: '/guide/IOS/filtering',
				name: 'guideIOSFiltering',
				component: () => import('../views/guide/IOS/filtering.vue'),
				meta: {
					title: "设置滤波参数",
					path: "/guide/IOS/filtering"
				}
			},
			{
				path: '/guide/IOS/monitorIndicator',
				name: 'guideIOSMonitorIndicator',
				component: () => import('../views/guide/IOS/monitorIndicator.vue'),
				meta: {
					title: "监听实时指标",
					path: "/guide/IOS/monitorIndicator"
				}
			},
			{
				path: '/guide/IOS/offLine',
				name: 'guideIOSOffLine',
				component: () => import('../views/guide/IOS/offLine.vue'),
				meta: {
					title: "查询离线数据",
					path: "/guide/IOS/offLine"
				}
			},
			{
				path: '/guide/IOS/merge-offline',
				name: 'guideIOSMergeOffLine',
				component: () => import('../views/guide/IOS/merge-offline.vue'),
				meta: {
					title: "合并离线数据",
					path: "/guide/IOS/merge-offline"
				}
			},
			{
				path: '/guide/IOS/unoffline',
				name: 'guideIOSUnoffline',
				component: () => import('../views/guide/IOS/unoffline.vue'),
				meta: {
					title: "取消离线数据合并",
					path: "/guide/IOS/unoffline"
				}
			},
			{
				path: '/guide/IOS/explanatoryDocument',
				name: 'guideIOSExplanatoryDocument',
				component: () => import('../views/guide/IOS/explanatoryDocument.vue'),
				meta: {
					title: "说明文档",
					path: "/guide/IOS/explanatoryDocument"
				}
			},
			
			// IOS SDK 帮助文档
			// {
			// 	path: '/guide/IOS/sdk/ConnectResultType',
			// 	name: 'iosConnectResultType',
			// 	component: () => import('../views/guide/IOS/sdk/ConnectResultType.vue'),
			// 	meta: {
			// 		title: "ConnectResultType",
			// 		path: "/guide/IOS/sdk/ConnectResultType"
			// 	}
			// },
			// {
			// 	path: '/guide/IOS/sdk/OnlineStageDelegate',
			// 	name: 'iosOnlineStageDelegate',
			// 	component: () => import('../views/guide/IOS/sdk/OnlineStageDelegate.vue'),
			// 	meta: {
			// 		title: "OnlineStageDelegate",
			// 		path: "/guide/IOS/sdk/OnlineStageDelegate"
			// 	}
			// },
			// {
			// 	path: '/guide/IOS/sdk/PasterConnectDelegate',
			// 	name: 'iosPasterConnectDelegate',
			// 	component: () => import('../views/guide/IOS/sdk/PasterConnectDelegate.vue'),
			// 	meta: {
			// 		title: "PasterConnectDelegate",
			// 		path: "/guide/IOS/sdk/PasterConnectDelegate"
			// 	}
			// },
			// {
			// 	path: '/guide/IOS/sdk/PickDataDelegate',
			// 	name: 'iosPickDataDelegate',
			// 	component: () => import('../views/guide/IOS/sdk/PickDataDelegate.vue'),
			// 	meta: {
			// 		title: "PickDataDelegate",
			// 		path: "/guide/IOS/sdk/PickDataDelegate"
			// 	}
			// },
			// {
			// 	path: '/guide/IOS/sdk/PWBluetoothModel',
			// 	name: 'iosPWBluetoothModel',
			// 	component: () => import('../views/guide/IOS/sdk/PWBluetoothModel.vue'),
			// 	meta: {
			// 		title: "PWBluetoothModel",
			// 		path: "/guide/IOS/sdk/PWBluetoothModel"
			// 	}
			// },
			// {
			// 	path: '/guide/IOS/sdk/PWDeviceTypeModel',
			// 	name: 'iosPWDeviceTypeModel',
			// 	component: () => import('../views/guide/IOS/sdk/PWDeviceTypeModel.vue'),
			// 	meta: {
			// 		title: "PWDeviceTypeModel",
			// 		path: "/guide/IOS/sdk/PWDeviceTypeModel"
			// 	}
			// },
			// {
			// 	path: '/guide/IOS/sdk/RealTimeDataDelegate',
			// 	name: 'iosRealTimeDataDelegate',
			// 	component: () => import('../views/guide/IOS/sdk/RealTimeDataDelegate.vue'),
			// 	meta: {
			// 		title: "RealTimeDataDelegate",
			// 		path: "/guide/IOS/sdk/RealTimeDataDelegate"
			// 	}
			// },
			// {
			// 	path: '/guide/IOS/sdk/RecordDelegate',
			// 	name: 'iosRecordDelegate',
			// 	component: () => import('../views/guide/IOS/sdk/RecordDelegate.vue'),
			// 	meta: {
			// 		title: "RecordDelegate",
			// 		path: "/guide/IOS/sdk/RecordDelegate"
			// 	}
			// },
			// {
			// 	path: '/guide/IOS/sdk/RecordEventError',
			// 	name: 'iosRecordEventError',
			// 	component: () => import('../views/guide/IOS/sdk/RecordEventError.vue'),
			// 	meta: {
			// 		title: "RecordEventError",
			// 		path: "/guide/IOS/sdk/RecordEventError"
			// 	}
			// },
			// {
			// 	path: '/guide/IOS/sdk/ScanPasterDelegate',
			// 	name: 'iosScanPasterDelegate',
			// 	component: () => import('../views/guide/IOS/sdk/ScanPasterDelegate.vue'),
			// 	meta: {
			// 		title: "ScanPasterDelegate",
			// 		path: "/guide/IOS/sdk/ScanPasterDelegate"
			// 	}
			// },
			// {
			// 	path: '/guide/IOS/sdk/UserInfoModel',
			// 	name: 'iosUserInfoModel',
			// 	component: () => import('../views/guide/IOS/sdk/UserInfoModel.vue'),
			// 	meta: {
			// 		title: "UserInfoModel",
			// 		path: "/guide/IOS/sdk/UserInfoModel"
			// 	}
			// },
			// airDream Unity SDK
			{
				path: '/guide/Unity/access',
				name: 'guideUnityAccess',
				component: () => import('../views/guide/Unity/access.vue'),
				meta: {
					title: "快速接入SDK",
					path: "/guide/Unity/access"
				}
			},
			{
				path: '/guide/Unity/initialization',
				name: 'guideUnityInitialization',
				component: () => import('../views/guide/Unity/initialization.vue'),
				meta: {
					title: "初始化SDK",
					path: "/guide/Unity/initialization"
				}
			},
			{
				path: '/guide/Unity/scan',
				name: 'guideUnityScan',
				component: () => import('../views/guide/Unity/scan.vue'),
				meta: {
					title: "扫描设备",
					path: "/guide/Unity/scan"
				}
			},
			{
				path: '/guide/Unity/stopScan',
				name: 'guideUnitystopScan',
				component: () => import('../views/guide/Unity/stopScan.vue'),
				meta: {
					title: "停止扫描",
					path: "/guide/Unity/stopScan"
				}
			},
			{
				path: '/guide/Unity/connection',
				name: 'guideUnityConnection',
				component: () => import('../views/guide/Unity/connection.vue'),
				meta: {
					title: "连接设备",
					path: "/guide/Unity/connection"
				}
			},
			{
				path: '/guide/Unity/connectionStatus',
				name: 'guideUnityConnectionStatus',
				component: () => import('../views/guide/Unity/connectionStatus.vue'),
				meta: {
					title: "获取连接状态",
					path: "/guide/Unity/connectionStatus"
				}
			},
			{
				path: '/guide/Unity/dataMonitor',
				name: 'guideUnityDataMonitor',
				component: () => import('../views/guide/Unity/dataMonitor.vue'),
				meta: {
					title: "实时数据监听",
					path: "/guide/Unity/dataMonitor"
				}
			},
			{
				path: '/guide/Unity/intercept',
				name: 'guideUnityIntercept',
				component: () => import('../views/guide/Unity/intercept.vue'),
				meta: {
					title: "原始数据截取",
					path: "/guide/Unity/intercept"
				}
			},
			{
				path: '/guide/Unity/shutdown',
				name: 'guideUnityShutdown',
				component: () => import('../views/guide/Unity/shutdown.vue'),
				meta: {
					title: "关闭设备连接",
					path: "/guide/Unity/shutdown"
				}
			},
			{
				path: '/guide/Unity/frontalPatch',
				name: 'guideUnityFrontalPatch',
				component: () => import('../views/guide/Unity/frontalPatch.vue'),
				meta: {
					title: "是否佩戴额贴",
					path: "/guide/Unity/frontalPatch"
				}
			},
			{
				path: '/guide/Unity/electricQuantity',
				name: 'guideUnityElectricQuantity',
				component: () => import('../views/guide/Unity/electricQuantity.vue'),
				meta: {
					title: "获取设备电量",
					path: "/guide/Unity/electricQuantity"
				}
			},
			{
				path: '/guide/Unity/quality',
				name: 'guideUnityQuality',
				component: () => import('../views/guide/Unity/quality.vue'),
				meta: {
					title: "获取采集的数据质量",
					path: "/guide/Unity/quality"
				}
			},
			{
				path: '/guide/Unity/enableListening',
				name: 'guideUnityenableListening',
				component: () => import('../views/guide/Unity/enableListening.vue'),
				meta: {
					title: "开启注意力监听",
					path: "/guide/Unity/enableListening"
				}
			},
			{
				path: '/guide/Unity/startMeditating',
				name: 'guideUnitystartMeditating',
				component: () => import('../views/guide/Unity/startMeditating.vue'),
				meta: {
					title: "开始冥想",
					path: "/guide/Unity/startMeditating"
				}
			},
			{
				path: '/guide/Unity/endListening',
				name: 'guideUnityendListening',
				component: () => import('../views/guide/Unity/endListening.vue'),
				meta: {
					title: "结束注意力监听",
					path: "/guide/Unity/endListening"
				}
			},
			{
				path: '/guide/Unity/endMeditating',
				name: 'guideUnityendMeditating',
				component: () => import('../views/guide/Unity/endMeditating.vue'),
				meta: {
					title: "结束冥想",
					path: "/guide/Unity/endMeditating"
				}
			},
			{
				path: '/guide/Unity/startRecord',
				name: 'guideUnityStartRecord',
				component: () => import('../views/guide/Unity/startRecord.vue'),
				meta: {
					title: "开始记录",
					path: "/guide/Unity/startRecord"
				}
			},
			{
				path: '/guide/Unity/endRecord',
				name: 'guideUnityEndRecord',
				component: () => import('../views/guide/Unity/endRecord.vue'),
				meta: {
					title: "结束记录",
					path: "/guide/Unity/endRecord"
				}
			},
			{
				path: '/guide/Unity/addEvent',
				name: 'guideUnityAddEvent',
				component: () => import('../views/guide/Unity/addEvent.vue'),
				meta: {
					title: "数据采集过程中添加事件",
					path: "/guide/Unity/addEvent"
				}
			},
			{
				path: '/guide/Unity/sleep',
				name: 'guideUnitySleep',
				component: () => import('../views/guide/Unity/sleep.vue'),
				meta: {
					title: "监听睡眠分期",
					path: "/guide/Unity/sleep"
				}
			},
			{
				path: '/guide/Unity/filtering',
				name: 'guideUnityFiltering',
				component: () => import('../views/guide/Unity/filtering.vue'),
				meta: {
					title: "设置滤波参数",
					path: "/guide/Unity/filtering"
				}
			},
			{
				path: '/guide/Unity/bodyPosition',
				name: 'guideUnityBodyPosition',
				component: () => import('../views/guide/Unity/bodyPosition.vue'),
				meta: {
					title: "获取体位",
					path: "/guide/Unity/bodyPosition"
				}
			},
			// CSharp SDK
			{
				path: '/guide/CSharp/access',
				name: 'guideCSharpAccess',
				component: () => import('../views/guide/CSharp/access.vue'),
				meta: {
					title: "快速接入SDK",
					path: "/guide/CSharp/access"
				}
			},
			{
				path: '/guide/CSharp/initialization',
				name: 'guideCSharpInitialization',
				component: () => import('../views/guide/CSharp/initialization.vue'),
				meta: {
					title: "初始化SDK",
					path: "/guide/CSharp/initialization"
				}
			},
			{
				path: '/guide/CSharp/scan',
				name: 'guideCSharpScan',
				component: () => import('../views/guide/CSharp/scan.vue'),
				meta: {
					title: "扫描设备",
					path: "/guide/CSharp/scan"
				}
			},
			{
				path: '/guide/CSharp/stopScan',
				name: 'guideCSharpstopScan',
				component: () => import('../views/guide/CSharp/stopScan.vue'),
				meta: {
					title: "停止扫描",
					path: "/guide/CSharp/stopScan"
				}
			},
			{
				path: '/guide/CSharp/connection',
				name: 'guideCSharpConnection',
				component: () => import('../views/guide/CSharp/connection.vue'),
				meta: {
					title: "连接设备",
					path: "/guide/CSharp/connection"
				}
			},
			{
				path: '/guide/CSharp/connectionStatus',
				name: 'guideCSharpConnectionStatus',
				component: () => import('../views/guide/CSharp/connectionStatus.vue'),
				meta: {
					title: "获取连接状态",
					path: "/guide/CSharp/connectionStatus"
				}
			},
			{
				path: '/guide/CSharp/dataMonitor',
				name: 'guideCSharpDataMonitor',
				component: () => import('../views/guide/CSharp/dataMonitor.vue'),
				meta: {
					title: "实时数据监听",
					path: "/guide/CSharp/dataMonitor"
				}
			},
			{
				path: '/guide/CSharp/intercept',
				name: 'guideCSharpIntercept',
				component: () => import('../views/guide/CSharp/intercept.vue'),
				meta: {
					title: "原始数据截取",
					path: "/guide/CSharp/intercept"
				}
			},
			{
				path: '/guide/CSharp/shutdown',
				name: 'guideCSharpShutdown',
				component: () => import('../views/guide/CSharp/shutdown.vue'),
				meta: {
					title: "关闭设备连接",
					path: "/guide/CSharp/shutdown"
				}
			},
			{
				path: '/guide/CSharp/frontalPatch',
				name: 'guideCSharpFrontalPatch',
				component: () => import('../views/guide/CSharp/frontalPatch.vue'),
				meta: {
					title: "是否佩戴额贴",
					path: "/guide/CSharp/frontalPatch"
				}
			},
			{
				path: '/guide/CSharp/electricQuantity',
				name: 'guideCSharpElectricQuantity',
				component: () => import('../views/guide/CSharp/electricQuantity.vue'),
				meta: {
					title: "获取设备电量",
					path: "/guide/CSharp/electricQuantity"
				}
			},
			{
				path: '/guide/CSharp/quality',
				name: 'guideCSharpQuality',
				component: () => import('../views/guide/CSharp/quality.vue'),
				meta: {
					title: "获取采集的数据质量",
					path: "/guide/CSharp/quality"
				}
			},
			{
				path: '/guide/CSharp/enableListening',
				name: 'guideCSharpenableListening',
				component: () => import('../views/guide/CSharp/enableListening.vue'),
				meta: {
					title: "开启注意力监听",
					path: "/guide/CSharp/enableListening"
				}
			},
			{
				path: '/guide/CSharp/startMeditating',
				name: 'guideCSharpstartMeditating',
				component: () => import('../views/guide/CSharp/startMeditating.vue'),
				meta: {
					title: "开始冥想",
					path: "/guide/CSharp/startMeditating"
				}
			},
			{
				path: '/guide/CSharp/endListening',
				name: 'guideCSharpendListening',
				component: () => import('../views/guide/CSharp/endListening.vue'),
				meta: {
					title: "结束注意力监听",
					path: "/guide/CSharp/endListening"
				}
			},
			{
				path: '/guide/CSharp/endMeditating',
				name: 'guideCSharpendMeditating',
				component: () => import('../views/guide/CSharp/endMeditating.vue'),
				meta: {
					title: "结束冥想",
					path: "/guide/CSharp/endMeditating"
				}
			},
			{
				path: '/guide/CSharp/startRecord',
				name: 'guideCSharpStartRecord',
				component: () => import('../views/guide/CSharp/startRecord.vue'),
				meta: {
					title: "开始记录",
					path: "/guide/CSharp/startRecord"
				}
			},
			{
				path: '/guide/CSharp/endRecord',
				name: 'guideCSharpEndRecord',
				component: () => import('../views/guide/CSharp/endRecord.vue'),
				meta: {
					title: "结束记录",
					path: "/guide/CSharp/endRecord"
				}
			},
			{
				path: '/guide/CSharp/addEvent',
				name: 'guideCSharpAddEvent',
				component: () => import('../views/guide/CSharp/addEvent.vue'),
				meta: {
					title: "数据采集过程中添加事件",
					path: "/guide/CSharp/addEvent"
				}
			},
			{
				path: '/guide/CSharp/sleep',
				name: 'guideCSharpSleep',
				component: () => import('../views/guide/CSharp/sleep.vue'),
				meta: {
					title: "监听睡眠分期",
					path: "/guide/CSharp/sleep"
				}
			},
			{
				path: '/guide/CSharp/filtering',
				name: 'guideCSharpFiltering',
				component: () => import('../views/guide/CSharp/filtering.vue'),
				meta: {
					title: "设置滤波参数",
					path: "/guide/CSharp/filtering"
				}
			},
			{
				path: '/guide/CSharp/bodyPosition',
				name: 'guideCSharpBodyPosition',
				component: () => import('../views/guide/CSharp/bodyPosition.vue'),
				meta: {
					title: "获取体位",
					path: "/guide/CSharp/bodyPosition"
				}
			},
			// AirBand Android SDK
			{
				path: '/guide/airBand/android/access',
				name: 'airBandAndroidAccess',
				component: () => import('../views/guide/airBand/android/access.vue'),
				meta: {
					title: "快速接入SDK",
					path: "/guide/airBand/android/access"
				}
			},
			// 数据采集SDK
			{
				path: '/guide/airBand/android/collection/register',
				name: 'airBandAndroidCollectionRegister',
				component: () => import('../views/guide/airBand/android/collection/register.vue'),
				meta: {
					title: "注册数据接收",
					path: "/guide/airBand/android/collection/register"
				}
			},
			{
				path: '/guide/airBand/android/collection/connectionStatus',
				name: 'airBandAndroidCollectionConnectionStatus',
				component: () => import('../views/guide/airBand/android/collection/connectionStatus.vue'),
				meta: {
					title: "获取连接状态",
					path: "/guide/airBand/android/collection/connectionStatus"
				}
			},
			{
				path: '/guide/airBand/android/collection/connection',
				name: 'airBandAndroidCollectionConnection',
				component: () => import('../views/guide/airBand/android/collection/connection.vue'),
				meta: {
					title: "连接设备",
					path: "/guide/airBand/android/collection/connection"
				}
			},
			{
				path: '/guide/airBand/android/collection/stopScan',
				name: 'airBandAndroidCollectionStopScan',
				component: () => import('../views/guide/airBand/android/collection/stopScan.vue'),
				meta: {
					title: "停止扫描",
					path: "/guide/airBand/android/collection/stopScan"
				}
			},
			{
				path: '/guide/airBand/android/collection/scan',
				name: 'airBandAndroidCollectionScan',
				component: () => import('../views/guide/airBand/android/collection/scan.vue'),
				meta: {
					title: "蓝牙搜索",
					path: "/guide/airBand/android/collection/scan"
				}
			},
			// 手势推理SDK
			{
				path: '/guide/airBand/android/collection/gesture/open',
				name: 'airBandAndroidCollectionGestureOpen',
				component: () => import('../views/guide/airBand/android/gesture/open.vue'),
				meta: {
					title: "开启手势推理",
					path: "/guide/airBand/android/collection/gesture/open"
				}
			},
			{
				path: '/guide/airBand/android/collection/gesture/stop',
				name: 'airBandAndroidCollectionGestureStop',
				component: () => import('../views/guide/airBand/android/gesture/stop.vue'),
				meta: {
					title: "停止手势推理",
					path: "/guide/airBand/android/collection/gesture/stop"
				}
			},
			{
				path: '/guide/airBand/android/collection/gesture/set',
				name: 'airBandAndroidCollectionGestureSet',
				component: () => import('../views/guide/airBand/android/gesture/set.vue'),
				meta: {
					title: "设置手势结果的监听",
					path: "/guide/airBand/android/collection/gesture/set"
				}
			},

			// Windows平台 SDK
			{
				path: '/guide/Windows/sdk',
				name: 'WindowsSdk',
				component: () => import('../views/guide/Windows/sdk.vue'),
				meta: {
					title: "Windows平台 SDK",
					path: "/guide/Windows/sdk"
				}
			},
			// Liunx平台 SDK
			{
				path: '/guide/Liunx/sdk',
				name: 'LiunxSdk',
				component: () => import('../views/guide/Liunx/sdk.vue'),
				meta: {
					title: "Liunx平台 SDK",
					path: "/guide/Liunx/sdk"
				}
			},
			// API目录
			{
				path: '/guide/api/record',
				name: 'guideAPIRecord',
				component: () => import('../views/guide/api-record.vue'),
				meta: {
					title: "发布记录",
					path: "/guide/api/record"
				}
			},
			{
				path: '/guide/api/introduction',
				name: 'guideAPIIntroduction',
				component: () => import('../views/guide/api-introduction.vue'),
				meta: {
					title: "柔灵API简介",
					path: "/guide/api/introduction"
				}
			},
			{
				path: '/guide/api/catalog/access',
				name: 'catalogAccess',
				component: () => import('../views/guide/catalog/access.vue'),
				meta: {
					title: "快速接入",
					path: "/guide/api/catalog/access"
				}
			},
			{
				path: '/guide/api/catalog/signature',
				name: 'catalogSignature',
				component: () => import('../views/guide/catalog/signature.vue'),
				meta: {
					title: "sign签名规则",
					path: "/guide/api/catalog/signature"
				}
			},
			{
				path: '/guide/api/catalog/authorization',
				name: 'catalogAuthorization',
				component: () => import('../views/guide/catalog/authorization.vue'),
				meta: {
					title: "获取授权",
					path: "/guide/api/catalog/authorization"
				}
			},
			{
				path: '/guide/api/catalog/bind',
				name: 'catalogBind',
				component: () => import('../views/guide/catalog/bind.vue'),
				meta: {
					title: "绑定设备",
					path: "/guide/api/catalog/bind"
				}
			},
			{
				path: '/guide/api/catalog/edit',
				name: 'catalogEdit',
				component: () => import('../views/guide/catalog/edit.vue'),
				meta: {
					title: "修改设备",
					path: "/guide/api/catalog/edit"
				}
			},
			{
				path: '/guide/api/catalog/unbind',
				name: 'catalogUnbind',
				component: () => import('../views/guide/catalog/unbind.vue'),
				meta: {
					title: "解绑设备",
					path: "/guide/api/catalog/unbind"
				}
			},
			{
				path: '/guide/api/catalog/list',
				name: 'catalogList',
				component: () => import('../views/guide/catalog/list.vue'),
				meta: {
					title: "设备列表",
					path: "/guide/api/catalog/list"
				}
			},
			{
				path: '/guide/api/catalog/query',
				name: 'catalogQuery',
				component: () => import('../views/guide/catalog/query.vue'),
				meta: {
					title: "查询指定设备",
					path: "/guide/api/catalog/query"
				}
			},
			{
				path: '/guide/api/catalog/details',
				name: 'catalogDetails',
				component: () => import('../views/guide/catalog/details.vue'),
				meta: {
					title: "睡眠报告明细",
					path: "/guide/api/catalog/details"
				}
			},
			{
				path: '/guide/api/catalog/reportList',
				name: 'catalogReportList',
				component: () => import('../views/guide/catalog/reportList.vue'),
				meta: {
					title: "睡眠报告列表",
					path: "/guide/api/catalog/reportList"
				}
			},
			{
				path: '/guide/api/catalog/deviceAcquisitionData',
				name: 'catalogDeviceAcquisitionData',
				component: () => import('../views/guide/catalog/deviceAcquisitionData.vue'),
				meta: {
					title: "实时获取设备采集数据",
					path: "/guide/api/catalog/deviceAcquisitionData"
				}
			},
			{
				path: '/guide/api/catalog/generateMeditateReport',
				name: 'catalogGenerateMeditateReport',
				component: () => import('../views/guide/catalog/generateMeditateReport.vue'),
				meta: {
					title: "生成冥想报告",
					path: "/guide/api/catalog/generateMeditateReport"
				}
			},
			{
				path: '/guide/api/catalog/meditateReport',
				name: 'catalogMeditateReport',
				component: () => import('../views/guide/catalog/meditateReport.vue'),
				meta: {
					title: "冥想报告列表",
					path: "/guide/api/catalog/meditateReport"
				}
			},
			{
				path: '/guide/api/catalog/meditateReportDetails',
				name: 'catalogMeditateReportDetails',
				component: () => import('../views/guide/catalog/meditateReportDetails.vue'),
				meta: {
					title: "冥想报告详情",
					path: "/guide/api/catalog/meditateReportDetails"
				}
			},
			{
				path: '/guide/api/catalog/generateReport',
				name: 'catalogGenerateReport',
				component: () => import('../views/guide/catalog/generateReport.vue'),
				meta: {
					title: "生成睡眠报告",
					path: "/guide/api/catalog/generateReport"
				}
			},
			{
				path: '/guide/api/catalog/classDocument',
				name: 'catalogClassDocument',
				component: () => import('../views/guide/catalog/classDocument.vue'),
				meta: {
					title: "说明文档",
					path: "/guide/api/catalog/classDocument"
				}
			},
			// nodeJsApi目录
			{
				path: '/guide/api/nodeJs/access',
				name: 'nodeJsAccess',
				component: () => import('../views/guide/nodeJS/access.vue'),
				meta: {
					title: "快速接入",
					path: "/guide/api/nodeJs/access"
				}
			},
			{
				path: '/guide/api/nodeJs/signature',
				name: 'nodeJsSignature',
				component: () => import('../views/guide/nodeJS/signature.vue'),
				meta: {
					title: "sign签名规则",
					path: "/guide/api/nodeJs/signature"
				}
			},
			{
				path: '/guide/api/nodeJs/authorization',
				name: 'nodeJsAuthorization',
				component: () => import('../views/guide/nodeJS/authorization.vue'),
				meta: {
					title: "获取授权",
					path: "/guide/api/nodeJs/authorization"
				}
			},
			{
				path: '/guide/api/nodeJs/bind',
				name: 'nodeJsBind',
				component: () => import('../views/guide/nodeJS/bind.vue'),
				meta: {
					title: "绑定设备",
					path: "/guide/api/nodeJs/bind"
				}
			},
			{
				path: '/guide/api/nodeJs/edit',
				name: 'nodeJsEdit',
				component: () => import('../views/guide/nodeJS/edit.vue'),
				meta: {
					title: "修改设备",
					path: "/guide/api/nodeJs/edit"
				}
			},
			{
				path: '/guide/api/nodeJs/unbind',
				name: 'nodeJsUnbind',
				component: () => import('../views/guide/nodeJS/unbind.vue'),
				meta: {
					title: "解绑设备",
					path: "/guide/api/nodeJs/unbind"
				}
			},
			{
				path: '/guide/api/nodeJs/list',
				name: 'nodeJsList',
				component: () => import('../views/guide/nodeJS/list.vue'),
				meta: {
					title: "设备列表",
					path: "/guide/api/nodeJs/list"
				}
			},
			{
				path: '/guide/api/nodeJs/query',
				name: 'nodeJsQuery',
				component: () => import('../views/guide/nodeJS/query.vue'),
				meta: {
					title: "查询指定设备",
					path: "/guide/api/nodeJs/query"
				}
			},
			{
				path: '/guide/api/nodeJs/details',
				name: 'nodeJsDetails',
				component: () => import('../views/guide/nodeJS/details.vue'),
				meta: {
					title: "睡眠报告明细",
					path: "/guide/api/nodeJs/details"
				}
			},
			{
				path: '/guide/api/nodeJs/reportList',
				name: 'nodeJsReportList',
				component: () => import('../views/guide/nodeJS/reportList.vue'),
				meta: {
					title: "睡眠报告列表",
					path: "/guide/api/nodeJs/reportList"
				}
			},
			{
				path: '/guide/api/nodeJs/deviceAcquisitionData',
				name: 'nodeJsDeviceAcquisitionData',
				component: () => import('../views/guide/nodeJS/deviceAcquisitionData.vue'),
				meta: {
					title: "实时获取设备采集数据",
					path: "/guide/api/nodeJs/deviceAcquisitionData"
				}
			},
			{
				path: '/guide/api/nodeJs/generateMeditateReport',
				name: 'nodeJsGenerateMeditateReport',
				component: () => import('../views/guide/nodeJS/generateMeditateReport.vue'),
				meta: {
					title: "生成冥想报告",
					path: "/guide/api/nodeJs/generateMeditateReport"
				}
			},
			{
				path: '/guide/api/nodeJs/meditateReport',
				name: 'nodeJsMeditateReport',
				component: () => import('../views/guide/nodeJS/meditateReport.vue'),
				meta: {
					title: "冥想报告列表",
					path: "/guide/api/nodeJs/meditateReport"
				}
			},
			{
				path: '/guide/api/nodeJs/meditateReportDetails',
				name: 'nodeJsMeditateReportDetails',
				component: () => import('../views/guide/nodeJS/meditateReportDetails.vue'),
				meta: {
					title: "冥想报告详情",
					path: "/guide/api/nodeJs/meditateReportDetails"
				}
			},
			{
				path: '/guide/api/nodeJs/generateReport',
				name: 'nodeJsGenerateReport',
				component: () => import('../views/guide/nodeJS/generateReport.vue'),
				meta: {
					title: "生成睡眠报告",
					path: "/guide/api/nodeJs/generateReport"
				}
			},
			{
				path: '/guide/api/nodeJs/classDocument',
				name: 'nodeJsClassDocument',
				component: () => import('../views/guide/nodeJS/classDocument.vue'),
				meta: {
					title: "说明文档",
					path: "/guide/api/nodeJs/classDocument"
				}
			},

			// API调用示例
			{
				path: '/guide/api/example/javaApi',
				name: 'exampleJavaApi',
				component: () => import('../views/guide/example/javaApi.vue'),
				meta: {
					title: "Java API SDK",
					path: "/guide/api/example/javaApi"
				}
			},
			{
				path: '/guide/api/example/phpApi',
				name: 'examplePhpApi',
				component: () => import('../views/guide/example/phpApi.vue'),
				meta: {
					title: "PHP API SDK",
					path: "/guide/api/example/phpApi"
				}
			},
			{
				path: '/guide/api/example/nodeApi',
				name: 'exampleNodeApi',
				component: () => import('../views/guide/example/nodeApi.vue'),
				meta: {
					title: "Node.js API SDK",
					path: "/guide/api/example/nodeApi"
				}
			},
			{
				path: '/guide/api/example/cApi',
				name: 'exampleCApi',
				component: () => import('../views/guide/example/cApi.vue'),
				meta: {
					title: "C# API SDK",
					path: "/guide/api/example/cApi"
				}
			},
			// 404
			{
				path: '/:catchAll(.*)',
				component: () => import(`@/views/error/404.vue`),
				hidden: true
			}


		],
		name: 'layout',
		meta: {}
	},
]
const router = createRouter({
	history: createWebHashHistory(process.env.BASE_URL),
	routes,
	scrollBehavior(to, from, savedPosition) {
		return {
			x: 0,
			y: 0
		}
	}
})
// 生成路由
// function generateRoutes(menus = []) {
// 	const routes = {
// 	    path: '/',
// 	    // component:(resolve) => require([`@/layout`], resolve),
// 	    component: () => import(`@/layout`),
// 		children: [],
// 		name: '',
// 		meta: {}
// 	}
// 	if(menus && menus.length > 0){
// 	  menus.forEach(m => {
// 		if (m.list && m.list.length > 0) {
// 		  m.list.forEach(element => {
// 			  const route = {
// 				name: element.menuPath,
// 				path: '/'+ element.menuPath,
// 				// closable:true,
// 				component: () => import(`@/views/${element.menuPath}`),
// 				meta: {
// 				  title: element.menuName,
// 				  icon: element.icon,
// 				  closable: null,
// 				  path: '/'+ element.menuPath,
// 				  closable:true
// 				}
// 			  }
// 			  routes.children.push(route)
// 		  });
// 		}
// 	  })
// 	}
  
// 	// 修复无首页时不显示404
// 	routes.children.push({
// 	  path: '',
// 	  hidden: true
// 	})
  
// 	routes.children.push({
// 		path: '/:catchAll(.*)',
// 		component: () => import(`@/views/error/404.vue`),
// 		hidden: true
// 	})
	
// 	return [routes]
//   }

// 添加路由
// export function addRoutes(menus = []) {
// 	// 生成动态路由
// 	if (menus && menus.length > 0) {
// 		const dynamicRoutes = generateRoutes(menus)
// 		for (let x of dynamicRoutes) {//新方式解决警告
// 			// router.addRoute("index", x);
// 			router.addRoute(x);
// 		}
// 	}
// }
// addRoutes(store.getters.menus)



function getPageTitle(pageTitle) {
	return pageTitle
  }
// 登出
// export function toLogout() {
// 	store.dispatch('user/logout')
// 	if (defaultSettings.is4) {
// 		logout()
// 	} else {
// 		router.push('/login')
// 	}
// }
// 登录
// function toLogin(to, next) {
// 	if (defaultSettings.is4) {
// 	  if (to.path === '/callback' || to.path === '/refresh') {
// 		next()
// 	  } else {
// 		login()
// 	  }
// 	} else {
// 	  if (to.path === '/login') {
// 		next()
// 	  } else {
// 		next(`/login?redirect=${to.path}`)
// 	  }
// 	}
//   }

// 设置缓存视图
// function setCachedViews() {
// 	// 获取视图缓存
// 	let sessionStorageTabs = sessionStorage.getItem('tabs')
// 	sessionStorageTabs = sessionStorageTabs ? JSON.parse(sessionStorageTabs) : []
// 	const cachedViews = sessionStorageTabs.map(t => t.name)
// 	// 设置存储视图缓存名
// 	store.commit('tabsView/set_cached_view', cachedViews)
//   }
  
//   let first = true
  // 路由全局前置守卫
  router.beforeEach(async(to, from, next) => {
	if(to.meta.title === '' || to.meta.title === undefined || to.meta.title === null){
	  document.title = '柔灵科技开放平台'
	}else{
	  document.title = getPageTitle(to.meta.title)
	}
	next()
	// to.meta.keepAlive = false;
	// const token = getToken()
  
	// setCachedViews()
  
	// if (token) {
	//   if (to.path === '/login') {
	// 	toLogin(to, next)
	//   } else {
	// 	const hasPermission = store.getters.menus && store.getters.menus.length > 0
	// 	if (hasPermission) {
	// 	  next()
	// 	} else {
	// 	  // 仅执行一次
	// 	  if (first) {
	// 		first = false
	// 		const res = await store.dispatch('user/getLoginInfo')
	// 		if (res && res.success) {
	// 		  if (!(res.data?.menuList?.length > 0)) {
	// 			toLogin(to, next)
	// 			return
	// 		  }
	// 		  next({ ...to, replace: true })
	// 		} else {
	// 		  toLogin(to, next)
	// 		}
	// 	  }
	// 	}
	//   }
	// } else {
	//   toLogin(to, next)
	// }
  })
export default router