// eslint-disable-next-line no-unused-vars
import { getToken, setToken, removeToken } from '@/utils/auth'
// import { resetRouter, addRoutes } from '@/router'
// 新特换接口
// import { login,rlAdminUserInfo} from '@/api/system/index'


const state = {
  token: getToken(),
  name: '',
  avatar: require('@/assets/images/avatar.png'),
  menus:[],
  permissions: [],
  cunChuTimeFalse:false,
  sleepReport:{},
  userIofoList:[],
  myMenus:[
    {
      id: 1,
      label: '柔灵开放平台',
      children: [
        {
          id: 11,
          label: '概述',
          path: '/platform/overview',
        },
        {
          label: '更新说明',
          path: '/platform/update',
        },
        // 接入指南
        {
          id: 12,
          label: '接入指南',
          children: [
            // 接入SDK
            {
              id: 121,
              label: '接入SDK',
              children: [
                // 发布记录
                {
                  id: 1211,
                  label: '发布记录',
                  path: '/guide/sdk/record',
                },
                // 柔灵SDK简介
                {
                  id: 1212,
                  label: '柔灵SDK简介',
                  path: '/guide/sdk/introduction',
                },
                // 小程序SDK
                {
                  label: '小程序SDK',
                  path: 'https://mp.weixin.qq.com/wxopen/plugindevdoc?appid=wx5c4a700b9e5facd7&token=&lang=zh_CN#-',
                },
                // UniApp Android SDK
                {
                  label:'UniApp Android SDK',
                  children:[
                    {
                      label: '快速接入SDK',
                      path: '/guide/UniApp/access',
                    },
                    {
                      label: '获取设备信息',
                      path: '/guide/UniApp/deviceInfo',
                    },
                    {
                      label: '扫描设备',
                      path: '/guide/UniApp/scan',
                    },
                    {
                      label: '停止扫描',
                      path: '/guide/UniApp/stopScan',
                    },
                    {
                      label: '连接设备',
                      path: '/guide/UniApp/connection',
                    },
                    {
                      label: '释放SDK',
                      path: '/guide/UniApp/releaseSDK',
                    },
                    {
                      label: '获取连接状态',
                      path: '/guide/UniApp/connectionStatus',
                    },
                    {
                      label: '实时数据监听',
                      path: '/guide/UniApp/dataMonitor',
                    },
                    {
                      label: '原始数据截取',
                      path: '/guide/UniApp/intercept',
                    },
                    {
                      label: '断开设备',
                      path: '/guide/UniApp/shutdown',
                    },
                    {
                      label: '获取设备佩戴状态',
                      path: '/guide/UniApp/wearingState',
                    },
                    {
                      label: '获取设备电量',
                      path: '/guide/UniApp/electricQuantity',
                    },
                    {
                      label: '数据质量检测',
                      path: '/guide/UniApp/quality',
                    },
                    {
                      label: '开始记录',
                      path: '/guide/UniApp/startRecord',
                    },
                    {
                      label: '结束记录',
                      path: '/guide/UniApp/endRecord',
                    },
                    {
                      label: '数据采集过程中添加事件',
                      path: '/guide/UniApp/addEvent',
                    },
                    {
                      label: '监听睡眠分期',
                      path: '/guide/UniApp/sleep',
                    },
                    
                    {
                      label: '获取体位',
                      path: '/guide/UniApp/bodyPosition',
                    },
                    
                    {
                      label: '获取信号强度',
                      path: '/guide/UniApp/equipmentSignal',
                    },
                    {
                      label: '设置滤波参数',
                      path: '/guide/UniApp/filtering',
                    }
                  ]
                },
                // Android SDK
                {
                  id:1213,
                  label:'AirDream Android SDK',
                  children:[
                    {
                      id: 12131,
                      label: '快速接入SDK',
                      path: '/guide/android/access',
                    },
                    {
                      id: 12132,
                      label: '初始化SDK',
                      path: '/guide/android/initialization',
                    },
                    {
                      label: '获取系统权限获取情况',
                      path: '/guide/android/system-permission',
                    },
                    {
                      id: 12133,
                      label: '扫描设备',
                      path: '/guide/android/scan',
                    },
                    {
                      id: 12134,
                      label: '停止扫描',
                      path: '/guide/android/stopScan',
                    },
                    {
                      id: 12135,
                      label: '连接设备',
                      path: '/guide/android/connection',
                    },
                    {
                      label: '开启数据实时上报',
                      path: '/guide/android/dataReporting',
                    },
                    {
                      id: 12137,
                      label: '获取连接状态',
                      path: '/guide/android/connectionStatus',
                    },
                    {
                      label: '实时数据监听',
                      path: '/guide/android/dataMonitor',
                    },
                    {
                      label: '原始数据截取',
                      path: '/guide/android/intercept',
                    },
                    {
                      id: 12136,
                      label: '关闭设备连接',
                      path: '/guide/android/shutdown',
                    },
                    {
                      label: '是否佩戴额贴',
                      path: '/guide/android/frontalPatch',
                    },
                    {
                      label: '获取设备电量',
                      path: '/guide/android/electricQuantity',
                    },
                    {
                      label: '获取采集的数据质量',
                      path: '/guide/android/quality',
                    },
                    {
                      label: '开始记录',
                      path: '/guide/android/startRecord',
                    },
                    {
                      label: '结束记录',
                      path: '/guide/android/endRecord',
                    },
                    {
                      label: '数据采集过程中添加事件',
                      path: '/guide/android/addEvent',
                    },
                    {
                      label: '监听睡眠分期',
                      path: '/guide/android/sleep',
                    },
                    
                    {
                      label: '获取体位',
                      path: '/guide/android/bodyPosition',
                    },
                    
                    {
                      label: '获取设备信号强度',
                      path: '/guide/android/equipmentSignal',
                    },
                    {
                      label: '设置滤波参数',
                      path: '/guide/android/filtering',
                    },
                    {
                      label: '监听实时指标',
                      path: '/guide/android/monitorIndicator',
                    },
                    {
                      label: '查询离线数据',
                      path: '/guide/android/offLine',
                    },
                    {
                      label: '合并离线数据',
                      path: '/guide/android/merge-offline',
                    },
                    {
                      label: '擦除设备离线数据',
                      path: '/guide/android/erasing-equipment',
                    },
                    {
                      label: '取消离线数据合并',
                      path: '/guide/android/unoffline',
                    },
                    {
                      label: '冥想信号检测',
                      path: '/guide/android/meditation-test',
                    },
                    {
                      label: '退出冥想检测',
                      path: '/guide/android/exit-detection',
                    },
                    {
                      label: '开始冥想',
                      path: '/guide/android/start-meditating',
                    },
                    {
                      label: '冥想数据记录',
                      path: '/guide/android/meditation-record',
                    },
                    {
                      label: '获取实时冥想值',
                      path: '/guide/android/meditation-score',
                    },
                    {
                      label: '结束冥想',
                      path: '/guide/android/end-meditation',
                    },
                    {
                      label: "说明文档",
					            path: "/guide/android/explanatoryDocument"
                    },

                    // {
                    //   label: 'Android SDK说明文档',
                    //   children:[
                    //     {
                    //       label: 'RecordEventCode',
                    //       path: '/guide/android/sdk/RecordEventCode',
                    //     },
                    //     {
                    //       label: 'ConnectResultType',
                    //       path: '/guide/android/sdk/ConnectResultType',
                    //     },
                    //     {
                    //       label: 'BleBean',
                    //       path: '/guide/android/sdk/BleBean',
                    //     },
                    //     {
                    //       label: 'ScanResultEvent',
                    //       path: '/guide/android/sdk/ScanResultEvent',
                    //     },
                        
                    //   ]
                    // }
                  ]
                },
                // IOS SDK
                {
                  label: 'AirDream IOS SDK',
                  children:[
                    {
                      label: '快速接入SDK',
                      path: '/guide/IOS/access',
                    },
                    {
                      label: '初始化SDK',
                      path: '/guide/IOS/initialization',
                    },
                    {
                      label: '扫描设备',
                      path: '/guide/IOS/scan',
                    },
                    {
                      label: '停止扫描',
                      path: '/guide/IOS/stopScan',
                    },
                    {
                      label: '连接设备',
                      path: '/guide/IOS/connection',
                    },
                    {
                      label: '获取连接状态',
                      path: '/guide/IOS/connectionStatus',
                    },
                    {
                      label: '实时数据监听',
                      path: '/guide/IOS/dataMonitor',
                    },
                    {
                      label: '原始数据截取',
                      path: '/guide/IOS/intercept',
                    },
                    {
                      label: '关闭设备连接',
                      path: '/guide/IOS/shutdown',
                    },
                    {
                      label: '是否佩戴了脑贴',
                      path: '/guide/IOS/frontalPatch',
                    },
                    {
                      label: '获取设备电量',
                      path: '/guide/IOS/electricQuantity',
                    },
                     {
                      label: '获取设备信号强度',
                      path: '/guide/IOS/equipmentSignal',
                    },
                    {
                      label: '获取采集的数据质量',
                      path: '/guide/IOS/quality',
                    },
                    {
                      label: '开始记录',
                      path: '/guide/IOS/startRecord',
                    },
                    {
                      label: '结束记录',
                      path: '/guide/IOS/endRecord',
                    },
                    {
                      label: '数据采集过程中添加事件',
                      path: '/guide/IOS/addEvent',
                    },
                    {
                      label: '获取设备信息',
                      path: '/guide/IOS/info',
                    },
                    {
                      label: '监听睡眠分期',
                      path: '/guide/IOS/sleep',
                    },
                    {
                      label: '设置滤波参数',
                      path: '/guide/IOS/filtering',
                    },
                    {
                      label: '监听实时指标',
                      path: '/guide/IOS/monitorIndicator',
                    },
                    {
                      label: "查询离线数据",
                      path: "/guide/IOS/offLine"
                    },
                    {
                      label: "合并离线数据",
                      path: "/guide/IOS/merge-offline"
                    },
                    {
                      label: "取消离线数据合并",
                      path: "/guide/IOS/unoffline"
                    },
                    {
                      label: "说明文档",
                      path: "/guide/IOS/explanatoryDocument"
                    },
                    // IOS SDK 帮助文档
                    // {
                    //   label: 'IOS SDK 帮助文档',
                    //   children:[
                    //     {
                    //       label: 'ConnectResultType',
                    //       path: '/guide/IOS/sdk/ConnectResultType',
                    //     },
                    //     {
                    //       label: 'OnlineStageDelegate',
                    //       path: '/guide/IOS/sdk/OnlineStageDelegate',
                    //     },
                    //     {
                    //       label: 'PasterConnectDelegate',
                    //       path: '/guide/IOS/sdk/PasterConnectDelegate',
                    //     },
                    //     {
                    //       label: 'PickDataDelegate',
                    //       path: '/guide/IOS/sdk/PickDataDelegate',
                    //     },
                    //     {
                    //       label: 'PWBluetoothModel',
                    //       path: '/guide/IOS/sdk/PWBluetoothModel',
                    //     },
                    //     {
                    //       label: 'PWDeviceTypeModel',
                    //       path: '/guide/IOS/sdk/PWDeviceTypeModel',
                    //     },
                    //     {
                    //       label: 'RealTimeDataDelegate',
                    //       path: '/guide/IOS/sdk/RealTimeDataDelegate',
                    //     },
                    //     {
                    //       label: 'RecordDelegate',
                    //       path: '/guide/IOS/sdk/RecordDelegate',
                    //     },
                    //     {
                    //       label: 'RecordEventError',
                    //       path: '/guide/IOS/sdk/RecordEventError',
                    //     },
                    //     {
                    //       label: 'ScanPasterDelegate',
                    //       path: '/guide/IOS/sdk/ScanPasterDelegate',
                    //     },
                    //     {
                    //       label: 'UserInfoModel',
                    //       path: '/guide/IOS/sdk/UserInfoModel',
                    //     }
                    //   ]
                    // }
                  ]
                },
                // airDream Unity SDK
                {
                  label:'AirDream Unity SDK',
                  children:[
                    {
                      label: '快速接入SDK',
                      path: '/guide/Unity/access',
                    },
                    {
                      label: '初始化SDK',
                      path: '/guide/Unity/initialization',
                    },
                    {
                      label: '扫描设备',
                      path: '/guide/Unity/scan',
                    },
                    {
                      label: '停止扫描',
                      path: '/guide/Unity/stopScan',
                    },
                    {
                      label: '连接设备',
                      path: '/guide/Unity/connection',
                    },
                    {
                      label: '关闭设备连接',
                      path: '/guide/Unity/shutdown',
                    },
                    {
                      label: '是否佩戴额贴',
                      path: '/guide/Unity/frontalPatch',
                    },
                    {
                      label: '获取连接状态',
                      path: '/guide/Unity/connectionStatus',
                    },
                    {
                      label: '实时数据监听',
                      path: '/guide/Unity/dataMonitor',
                    },
                    {
                      label: '原始数据截取',
                      path: '/guide/Unity/intercept',
                    },
                    {
                      label: '获取设备电量',
                      path: '/guide/Unity/electricQuantity',
                    },
                    {
                      label: '获取采集的数据质量',
                      path: '/guide/Unity/quality',
                    },
                    {
                      label: '开启注意力监听',
                      path: '/guide/Unity/enableListening',
                    },
                    {
                      label: '结束注意力监听',
                      path: '/guide/Unity/endListening',
                    },
                    {
                      label: '开始冥想',
                      path: '/guide/Unity/startMeditating',
                    },
                    {
                      label: '结束冥想',
                      path: '/guide/Unity/endMeditating',
                    },
                    {
                      label: '开始记录',
                      path: '/guide/Unity/startRecord',
                    },
                    {
                      label: '结束记录',
                      path: '/guide/Unity/endRecord',
                    },
                    {
                      label: '数据采集过程中添加事件',
                      path: '/guide/Unity/addEvent',
                    },
                    {
                      label: '监听睡眠分期',
                      path: '/guide/Unity/sleep',
                    },
                    {
                      label: '获取体位',
                      path: '/guide/Unity/bodyPosition',
                    },
                    {
                      label: '设置滤波参数',
                      path: '/guide/CSharp/filtering',
                    },
                  ]
                },
                // CSharp SDK
                {
                  label: 'AirDream C# SDK',
                  children:[
                    {
                      label: '快速接入SDK',
                      path: '/guide/CSharp/access',
                    },
                    {
                      label: '初始化SDK',
                      path: '/guide/CSharp/initialization',
                    },
                    {
                      label: '扫描设备',
                      path: '/guide/CSharp/scan',
                    },
                    {
                      label: '停止扫描',
                      path: '/guide/CSharp/stopScan',
                    },
                    {
                      label: '连接设备',
                      path: '/guide/CSharp/connection',
                    },
                    {
                      label: '关闭设备连接',
                      path: '/guide/CSharp/shutdown',
                    },
                    {
                      label: '是否佩戴额贴',
                      path: '/guide/CSharp/frontalPatch',
                    },
                    {
                      label: '获取连接状态',
                      path: '/guide/CSharp/connectionStatus',
                    },
                    {
                      label: '实时数据监听',
                      path: '/guide/CSharp/dataMonitor',
                    },
                    {
                      label: '原始数据截取',
                      path: '/guide/CSharp/intercept',
                    },
                    {
                      label: '获取设备电量',
                      path: '/guide/CSharp/electricQuantity',
                    },
                    {
                      label: '获取采集的数据质量',
                      path: '/guide/CSharp/quality',
                    },
                    {
                      label: '开启注意力监听',
                      path: '/guide/CSharp/enableListening',
                    },
                    {
                      label: '结束注意力监听',
                      path: '/guide/CSharp/endListening',
                    },
                    {
                      label: '开始冥想',
                      path: '/guide/CSharp/startMeditating',
                    },
                    {
                      label: '结束冥想',
                      path: '/guide/CSharp/endMeditating',
                    },
                    {
                      label: '开始记录',
                      path: '/guide/CSharp/startRecord',
                    },
                    {
                      label: '结束记录',
                      path: '/guide/CSharp/endRecord',
                    },
                    {
                      label: '数据采集过程中添加事件',
                      path: '/guide/CSharp/addEvent',
                    },
                    {
                      label: '监听睡眠分期',
                      path: '/guide/CSharp/sleep',
                    },
                    {
                      label: '获取体位',
                      path: '/guide/CSharp/bodyPosition',
                    },
                    {
                      label: '设置滤波参数',
                      path: '/guide/CSharp/filtering',
                    },
                  ]
                },
                // AirBand Android SDK
                {
                  label: 'AirBand Android SDK',
                  children:[
                    {
                      label: '快速接入SDK',
                      path: '/guide/airBand/android/access',
                    },
                    {
                      label: '数据采集SDK',
                      children:[
                        {
                          label: '注册数据接收',
                          path: '/guide/airBand/android/collection/register',
                        },
                        {
                          label: '获取连接状态',
                          path: '/guide/airBand/android/collection/connectionStatus',
                        },
                        {
                          label: '连接设备',
                          path: '/guide/airBand/android/collection/connection',
                        },
                        {
                          label: '停止扫描',
                          path: '/guide/airBand/android/collection/stopScan',
                        },
                        {
                          label: '蓝牙搜索',
                          path: '/guide/airBand/android/collection/scan',
                        },
                        {
                          label: '手势推理SDK',
                          children:[
                            {
                              label: '开启手势推理',
                              path: '/guide/airBand/android/collection/gesture/open',
                            },
                            {
                              label: '停止手势推理',
                              path: '/guide/airBand/android/collection/gesture/stop',
                            },
                            {
                              label: '设置手势结果的监听',
                              path: '/guide/airBand/android/collection/gesture/set',
                            },
                          ]
                        }
                      ]
                    },
                  ]
                },
                {
                  label: 'Windows平台 SDK',
                  path: '/guide/Windows/sdk',
                },
                {
                  label: 'Liunx平台 SDK',
                  path: '/guide/Liunx/sdk',
                }
              ],
            },
            // 接入API
            {
              id: 122,
              label: '接入API',
              children: [
                {
                  label: '发布记录',
                  path: '/guide/api/record',
                },
                { 
                  label: '柔灵API简介',
                  path: '/guide/api/introduction',
                },
                // API目录
                { 
                  label: 'API目录',
                  children:[
                    { 
                      label: '快速接入',
                      path: '/guide/api/catalog/access',
                    },
                    { 
                      label: 'sign签名规则',
                      path: '/guide/api/catalog/signature',
                    },
                    { 
                      label: '获取授权',
                      path: '/guide/api/catalog/authorization',
                    },
                    { 
                      label: '绑定设备',
                      path: '/guide/api/catalog/bind',
                    },
                    { 
                      label: '修改设备',
                      path: '/guide/api/catalog/edit',
                    },
                    { 
                      label: '解绑设备',
                      path: '/guide/api/catalog/unbind',
                    },
                    { 
                      label: '设备列表',
                      path: '/guide/api/catalog/list',
                    },
                    { 
                      label: '查询指定设备',
                      path: '/guide/api/catalog/query',
                    },
                    { 
                      label: '睡眠报告明细',
                      path: '/guide/api/catalog/details',
                    },
                    { 
                      label: '睡眠报告列表',
                      path: '/guide/api/catalog/reportList',
                    },
                    {
                      label:'实时获取设备采集数据',
                      path:'/guide/api/catalog/deviceAcquisitionData'
                    },
                    {
                      label:'生成冥想报告',
                      path:'/guide/api/catalog/generateMeditateReport'
                    },
                    {
                      label:'冥想报告列表',
                      path:'/guide/api/catalog/meditateReport'
                    },
                    {
                      label:'冥想报告详情',
                      path:'/guide/api/catalog/meditateReportDetails'
                    },
                    { 
                      label: '生成睡眠报告',
                      path: '/guide/api/catalog/generateReport',
                    },
                    {
                      label: "说明文档",
					            path: "/guide/api/catalog/classDocument"
                    }
                  ]
                },
                // NodeJS API目录
                {
                  label: 'NodeJS API',
                  children:[
                    { 
                      label: '快速接入',
                      path: '/guide/api/nodeJs/access',
                    },
                    { 
                      label: 'sign签名规则',
                      path: '/guide/api/nodeJs/signature',
                    },
                    { 
                      label: '获取授权',
                      path: '/guide/api/nodeJs/authorization',
                    },
                    { 
                      label: '绑定设备',
                      path: '/guide/api/nodeJs/bind',
                    },
                    { 
                      label: '修改设备',
                      path: '/guide/api/nodeJs/edit',
                    },
                    { 
                      label: '解绑设备',
                      path: '/guide/api/nodeJs/unbind',
                    },
                    { 
                      label: '设备列表',
                      path: '/guide/api/nodeJs/list',
                    },
                    { 
                      label: '查询指定设备',
                      path: '/guide/api/nodeJs/query',
                    },
                    { 
                      label: '睡眠报告明细',
                      path: '/guide/api/nodeJs/details',
                    },
                    { 
                      label: '睡眠报告列表',
                      path: '/guide/api/nodeJs/reportList',
                    },
                    {
                      label:'实时获取设备采集数据',
                      path:'/guide/api/nodeJs/deviceAcquisitionData'
                    },
                    {
                      label:'生成冥想报告',
                      path:'/guide/api/nodeJs/generateMeditateReport'
                    },
                    {
                      label:'冥想报告列表',
                      path:'/guide/api/nodeJs/meditateReport'
                    },
                    {
                      label:'冥想报告详情',
                      path:'/guide/api/nodeJs/meditateReportDetails'
                    },
                    { 
                      label: '生成睡眠报告',
                      path: '/guide/api/nodeJs/generateReport',
                    },
                    {
                      label: "说明文档",
					            path: "/guide/api/nodeJs/classDocument"
                    }
                  ]
                },
                // API调用示例
                { 
                  label: 'API SDK',
                  children:[
                    { 
                      label: 'Java API SDK',
                      path: '/guide/api/example/javaApi',
                    },
                    { 
                      label: 'PHP API SDK',
                      path: '/guide/api/example/phpApi',
                    },
                    { 
                      label: 'Node.js API SDK',
                      path: '/guide/api/example/nodeApi',
                    },
                    { 
                      label: 'C# API SDK',
                      path: '/guide/api/example/cApi',
                    },
                  ]
                },
              ]
            },
            // 错误码表
            {
              id: 123,
              label: '错误码表',
              path:'/error-code-table'
            },
          ],
        },
      ],
    }
  ],
}

const mutations = {
  setToken: (state, token) => {
    state.token = token
    setToken(token)
  },
  setName: (state, name) => {
    state.name = name
  },
  setAvatar: (state, avatar) => {
    if (avatar) {
      state.avatar = process.env.VUE_APP_AVATAR_URL + avatar
    } else {
      state.avatar = require('@/assets/images/avatar.png')
    }
  },
  setMenus: (state, menus) => {
    state.menus = menus
  },
  setPermissions: (state, permissions) => {
    state.permissions = permissions
  },
  setCunChuTimeFalse:(state,cunChuTimeFalse)=>{
    state.cunChuTimeFalse = cunChuTimeFalse
  },
  setSleepReport:(state,sleepReport)=>{
    state.sleepReport = sleepReport
    localStorage.setItem('sleepReport',sleepReport)
  },
  setUserInfoList: (state, userIofoList) => {
    state.userIofoList = userIofoList
  },
  setMyMusList:(state,myMenus)=>{
    state.myMenus = myMenus
    localStorage.setItem('myMenus',myMenus)
  },
}

const actions = {
  // login登录
  async login({ commit }, paras) {
    const res = await login(paras)
    if (res && res.success) {
      const token = res.data.accessToken
      commit('setToken', token)
    }

    return res
  },

  async getLoginInfo({ commit }) {
    // 菜单信息
    const res = await rlAdminUserInfo()
    if (!res?.success) {
      return res
    }
    commit('setName', res.data.adminAccount)
    // commit('setAvatar', user.avatar)

    commit('setMenus', res.data.menuList)
    commit('setUserInfoList',res.data)
    localStorage.setItem('loginInfo',JSON.stringify(res.data))
    // commit('setPermissions', res.data.permissions)
    // localStorage.setItem('menuList',res.data.menuList)
    // addRoutes(res.data.menuList)
    return res
  },

  setLocalLoginInfo({ commit, dispatch, state }) {
    let loginInfo = window.localStorage.getItem('loginInfo')
    if (loginInfo) {
      loginInfo = JSON.parse(loginInfo)
      commit('setName', loginInfo.adminAccount)
      commit('setAvatar', loginInfo.avatar)
      commit('setMenus', loginInfo.menuList)
      // addRoutes(loginInfo.menuList)
    } else {
      dispatch('logout')
    }
  },

  logout({ commit }) {
    commit('setToken', '')
    removeToken()
    // resetRouter()
    sessionStorage.removeItem('tabs')
    // localStorage.removeItem('loginInfo')

    commit('tabsView/delete_all_cached_views', null, { root: true })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
