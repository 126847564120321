// import { useIntersectionObserver } from '@vueuse/core'
// import defaultImg from '@/assets/images/200.png'
// /***
//  * 参数一target表示被监听的DOM元素
//  * 参数二是回调函数，用于通知监听的动作（回调函数的第一个形参isIntersecting表示被监听的元素已经进入了可视区）
//  * 参数三 表示配置选项
//  * isIntersecting 是否进入可视区域，true是进入 false是移出
//  * observerElement 被观察的dom
//  */

// export default {
//   install (app) {
//     app.directive('imgLazy', {
//       mounted (el, binding) {
//         // 监听组件是否进入可视区
//         const { stop } = useIntersectionObserver(
//           el,
//           ([{ isIntersecting }], observerElement) => {
//             // el(img标签)进入了可视区域
//             if (isIntersecting) {
//               // 被监听的DOM进入了可视区:此时调用接口获取数据；停止继续监听
//               stop()

//               el.src = binding.value

//               // 当图片url无效加载失败的时候使用默认图片替代
//               el.onerror = function () {
//                 el.src = defaultImg
//               }
//             }
//           }
//         )
//       }
//     })
//   }
// }

import {mounted,mounted2} from '../utils/line'
import '../assets/styles/code.less';
// import {onMounted} from 'vue'
// 另外app参数是啥？一会你就知道了
// 创建一个 directive 变量，用来聚集所有自定义指令
export const directive = (app) => {
	app.directive('code', (el) => {
        mounted(el)
	})
    // 更多指令...
	// app.directive('xxx', (el) => {})
}
